<template>
  <div class="invite-banner">
    <img
      v-if="$store.state.window.to768"
      src="@/static/svg/main/invite/gradient.svg"
      class="invite-banner__gradient d"
    >

    <img
      v-else-if="!$store.state.window.to768 && $store.state.window.width < 1720"
      src="@/static/svg/main/invite/gradient-desktop-landscape.svg"
      class="invite-banner__gradient m"
    >

    <img
      v-else-if="$store.state.window.width > 1720"
      src="@/static/svg/main/invite/gradient-desktop.svg"
      class="invite-banner__gradient d"
    >

    <div class="invite-banner__content">
      <template
        v-if="!$store.state.window.to768"
      >
        <div class="flex">
          <h2>
            {{ $t('components.ui.pages.invite-banner.invite') }}

            <span>
              {{ $t('components.ui.pages.invite-banner.bonus') }}
            </span>
          </h2>

          <img
            src="@/static/svg/main/invite/icon.svg"
            class="invite-banner__content__icon"
          >
        </div>
      </template>

      <div
        v-else
        class="invite-banner__content-mobile"
      >
        <img
          src="@/static/svg/main/invite/mobile-icon.svg"
          class="mobile"
        >

        <h3>
          {{ $t('components.ui.pages.invite-banner.invite') }}

          <span>
            {{ $t('components.ui.pages.invite-banner.bonus') }}
          </span>
        </h3>

        <img
          src="@/static/images/invite-mobile-icon.png"
          class="mobile-h230"
        >
      </div>

      <BlueButton
        :text="$t('components.ui.pages.invite-banner.friends')"
        @click="$pop.up('invite')"
      />
    </div>
  </div>
</template>

<script>
import BlueButton from '@/ui/buttons/BlueButton.vue'

export default {
  components: {
    BlueButton
  }
}
</script>

<style lang="scss" scoped>
.invite-banner {
  position: relative;
  width: 270px;
  min-width: 275px;
  margin-left: 40px;
  padding: 40px 20px;
  background: rgba(14, 14, 27, 1);
  border-radius: 10px;
  overflow: hidden;

  &.mobile-menu {
    .invite-banner__content {
      width: 100%;
      padding: 0 23px !important;
    }
  }

  &.h230 {
    height: 230px;
    padding: 15px 15px 20px;

    .invite-banner__content {
      height: 100%;
      align-items: flex-start;

      .invite-banner__content-mobile {
        width: 100%;
        margin-bottom: 0;
        justify-content: space-between;

        img.mobile {
          display: none;
        }

        img.mobile-h230 {
          min-height: 140px;
          max-width: 140px;
          min-width: 140px;
          margin-left: 10px;
          display: block;
        }

        h3 {
          max-width: 100% !important;
          margin-left: 0;
          color: rgba(255, 255, 255, 1);
          text-align: left;
          font-size: 26px;
          line-height: 100%;
        }
      }
    }
  }

  img.invite-banner__gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }

  .invite-banner__content {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 2;

    h2 {
      color: rgba(255, 255, 255, 1);
      text-align: center;
      line-height: 38px;
      font-family: Regular;

      span {
        color: rgba(211, 247, 3, 1);
        font-family: Medium;
      }
    }

    .invite-banner__content__icon {
      margin: 60px 0 105px 0;
    }

    .invite-banner__content-mobile {
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      img.mobile-h230 {
        display: none;
      }

      h3 {
        max-width: 100% !important;
        margin-left: 15px;
        color: rgba(255, 255, 255, 1);
        text-align: left;
        font-family: Medium;
        font-size: 20px;
        line-height: 120%;

        span {
          color: rgba(211, 247, 3, 1);
          font-family: Bold;
          font-size: inherit;
          line-height: inherit;
        }
      }
    }
  }
}

@media screen and (max-width: 1720px) {
  .invite-banner {
    width: 100%;
    min-width: 100%;
    margin-left: 0;
    padding: 30px 20px;

    img.invite-banner__gradient {
      object-fit: cover;
    }

    .invite-banner__content {
      position: relative;
      align-items: center;

      .flex {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
      }

      h2 {
        text-align: left;
      }

      .invite-banner__content__icon {
        max-width: 100px;
        margin: 0 20px 0 0;
      }

      button {
        max-width: 250px !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .invite-banner {
    width: 100%;
    min-width: 100%;
    margin-left: 0;
    padding: 20px 15px;
  }
}

@media screen and (max-width: 475px) {
  .invite-banner {
    .invite-banner__content {
      button {
        max-width: 100% !important;
      }
    }
  }
}
</style>
