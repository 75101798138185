<template>
  <template
    v-if="$store.getters.isAuth && !$store.state.preloader.global"
  >
    <div class="default">
      <Sidebar
        v-if="!$store.state.window.to1024"
      />

      <div class="default__container">
        <div class="default__container__image">
          <img
            src="@/static/svg/page-gradient.svg"
            class="default__container__gradient"
          >
        </div>

        <div class="default__container__content">
          <div
            v-if="!$store.state.window.to1024"
            class="default__container__content__padding"
          >
            <Header />
          </div>

          <RouterView v-slot="{ Component }">
            <KeepAlive>
              <Component
                :is="Component"
                :key="$route.fullPath"
              />
            </KeepAlive>
          </RouterView>
        </div>

        <Footer
          v-if="!$store.state.window.to1024"
        />

        <MobileNavBar
          v-else
        />
      </div>
    </div>

    <template v-if="$store.state.window.to1024">
      <MobileHeader />

      <Transition>
        <MobileMenu />
      </Transition>
    </template>
  </template>
</template>

<script>
import Header from '@/modules/app/Header.vue'
import MobileHeader from '@/modules/app/MobileHeader.vue'
import Sidebar from '@/modules/app/Sidebar.vue'
import Footer from '@/modules/app/Footer.vue'
import MobileMenu from '@/modules/app/MobileHeaderMenu.vue'
import MobileNavBar from '@/modules/app/MobileNavBar.vue'

export default {
  components: {
    Header,
    MobileHeader,
    Sidebar,
    Footer,
    MobileMenu,
    MobileNavBar
  },
  data () {
    return {
      timeout: false
    }
  },
  mounted () {
    window.addEventListener('focus', this.updateUserBalance)
  },
  beforeUnmount () {
    window.removeEventListener('focus', this.updateUserBalance)
  },
  methods: {
    updateUserBalance () {
      if (!this.timeout) {
        this.$store.dispatch('fetchBalance', this.$axios)
        this.timeout = true

        setTimeout(() => {
          this.timeout = false
        }, 1000 * 60 * 5)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.default {
  min-height: 100vh;
  width: 100%;
  display: flex;
  background: rgba(var(--layout-color));

  @media screen and (max-width: 768px) {
    min-height: auto !important;
  }

  .default__container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .default__container__image {
      position: absolute;
      right: 0;
      height: 100%;
      width: 100%;
      overflow: hidden;

      img.default__container__gradient {
        position: absolute;
        top: 0;
        right: 0;
        max-height: 100%;
        z-index: 1;
      }
    }

    .default__container__content {
      position: relative;
      z-index: 2;

      @media screen and (max-width: 768px) {
        padding-top: 60px;
      }

      .default__container__content__padding {
        width: 100%;
        padding: 0 10px;

        @media screen and (max-width: 768px) {
          padding: 0;
        }
      }
    }
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity .5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
