<template>
  <div class="balance">
    <p class="balance__text little-text">
      {{ $t('components.app.info-balance.balance') }}:
    </p>

    <div class="balance__container">
      <h3 class="balance__container__text">
        $ {{ $format.wallet(_user.balance) }}

        <button
          class="withdrawal"
          @click="$pop.up('withdrawal'), $menu.down()"
        >
          {{ $t('components.app.info-balance.withdrawal') }}
        </button>
      </h3>
    </div>

    <p class="balance__text small-text">
      {{ $t('components.app.info-balance.month-income', { value: $format.wallet(_user.per_month?.toFixed(2) || 0) }) }}
    </p>
  </div>
</template>

<script>
export default {
  data () {
    return {
      visible: false
    }
  },
  computed: {
    _user () {
      return this.$store.state.user.balance
    }
  },
  methods: {
    hide () {
      setTimeout(() => {
        this.visible = false
      }, 250)
    }
  }
}
</script>

<style lang="scss" scoped>
.balance {
  position: relative;
  padding: 0;
  z-index: 4;

  p.balance__text.little-text {
    color: rgba(var(--text-color-light));
    font-family: Medium;
    line-height: 85%;
  }

  .balance__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  h3.balance__container__text {
    display: flex;
    align-items: center;
    color: rgba(var(--text-color));
    font-family: SemiBold;

    button.withdrawal {
      margin-left: 10px;
      color: #3E63EB;
      font-family: Medium;
      font-size: 14px;
      transition: .2s;

      &:hover {
        color: rgb(var(--color-light-blue));
      }
    }

    .balance__container__text__button {
      height: 24px;
      width: 24px;
      margin-left: 6px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        transition: .2s;

        &.rotate {
          transform: rotate(180deg);
        }
      }
    }
  }

  p.balance__text.small-text {
    color: rgba(var(--color-green));
    font-family: Medium;
    line-height: 85%;
  }

  .balance__withdrawal {
    position: absolute;
    left: 0;
    bottom: -23px;
    max-width: 195px;
    z-index: 2;
  }
}
</style>
