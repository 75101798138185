<template>
  <header class="header">
    <RouterLink
      :to="{ name: 'Main' }"
      class="header__link"
    >
      <img src="@/static/svg/logo.svg">
    </RouterLink>

    <nav>
      <RouterLink
        :to="{ name: 'Balance' }"
        class="header__balance"
      >
        <p class="label">
          {{ $t('components.app.info-balance.balance') }}
        </p>

        <p class="val">
          $ {{ $format.wallet(_user.balance) }}
        </p>
      </RouterLink>

      <div class="header__nav">
        <button
          class="header__nav__button"
          @click.stop="$store.state.menu.visible ? $menu.down() : $menu.up()"
        >
          <svg
            v-if="!$store.state.menu.visible"
            width="28"
            height="22"
            style="min-width: 28px; min-height: 22px;"
            viewBox="0 0 28 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M2 2H26M2 11H26M2 20H26" stroke="white" stroke-width="3" stroke-linecap="round"/>
          </svg>

          <svg
            v-else
            width="22"
            height="22"
            style="min-width: 22px; min-height: 22px;"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M2 2L11 11M11 11L20 20M11 11L2 20M11 11L20 2" stroke="white" stroke-width="3" stroke-linecap="round"/>
          </svg>
        </button>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  computed: {
    _user () {
      return this.$store.state.user.balance
    }
  }
}
</script>

<style lang="scss" scoped>
header.header {
  position: fixed;
  top: 0;
  height: 60px;
  width: 100%;
  padding: 10px 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #0E0E1B;
  z-index: 19998500;

  .header__link {
    height: 26px;

    img {
      height: 100%;
    }
  }

  nav {
    display: flex;
    align-items: center;

    .header__balance {
      text-align: right;
      text-decoration: none;

      p.label {
        font-size: 12px;
        line-height: 100%;
        color: #9CA3B8;
        font-family: Medium;
      }

      p.val {
        margin-top: 9px;
        font-size: 18px;
        line-height: 100%;
        color: #FFFFFF;
        font-family: SemiBold;
      }
    }

    .header__nav {
      height: 40px;
      margin-left: 15px;
      padding-left: 15px;
      display: flex;
      align-items: center;
      border-left: 1px solid rgba(var(--border-line-color));

      .header__nav__button {
        height: 36px;
        width: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
