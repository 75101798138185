<template>
  <footer class="footer">
    <div class="footer__info">
      <RouterLink
        :to="{ name: 'PrivacyPolicy' }"
      >
        {{ $t('components.app.footer.privacy-policy') }}
      </RouterLink>

      <RouterLink
        :to="{ name: 'PublicOffer' }"
      >
        {{ $t('components.app.footer.public-offer') }}
      </RouterLink>

      <p>
        © OnliX | {{ $t('components.app.default.rights', { year: year }) }}
      </p>
    </div>

    <div class="footer__social">
      <a
        v-for="item in social"
        :key="item.name"
        :href="item.link"
        target="__blank"
        class="footer__social__link"
      >
        <img :src="require(`@/static/svg/social/${item.name}.svg`)">
      </a>
    </div>
  </footer>
</template>

<script>
export default {
  data () {
    return {
      year: new Date().getFullYear()
    }
  },
  computed: {
    social () {
      return this.$store.state.social.data
    }
  }
}
</script>

<style lang="scss" scoped>
footer.footer {
  position: relative;
  height: auto;
  min-height: 60px;
  width: 100%;
  margin-top: 100px;
  padding: 15px 40px;
  display: flex;
  justify-content: space-between;
  background: rgba(var(--footer-color));
  z-index: 2;

  .footer__info {
    display: flex;
    gap: 15px 25px;
    flex-wrap: wrap;
    align-items: center;

    p, a {
      color: rgb(var(--color-dark));
      font-size: 16px;
      line-height: 100%;
      text-decoration: none;
      white-space: nowrap;
      transition: .2s;
    }

    a:hover {
      color: #2F57E9;
    }
  }

  .footer__social {
    display: flex;
    align-items: center;

    a.footer__social__link {
      height: 36px;
      width: 36px;
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(var(--primary-color), .2);
      border-radius: 50%;
      transition: .2s;

      img {
        transition: .2s;
      }

      &:hover {
        background: #2F57E9;

        img {
          filter: brightness(0) invert(1);
        }
      }
    }
  }
}
</style>
